import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { VariableSizeList as List } from 'react-window';
import { useDebounce } from 'use-debounce';
import { api, Input } from '@webfx/core-web';
import { useAuth, useApp } from '@webfx/web-hooks';
import { useResize } from '@webfx/web-context';
import DropdownItem from '../DropdownItem';

import styles from './Menu.module.css';

const RowItem = React.forwardRef(({ data, index, style }, ref) => {
  const site = data[index];
  const isSelected = site.item.siteId === data.selectedSiteId;
  return (
    <Dropdown.Item
      ref={ref}
      eventKey={site.item}
      key={site.item.siteId}
      activeSite={isSelected}
      style={style}
      data={site}
      as={DropdownItem}
    >
      {site.item.name ?? site.item.url}
    </Dropdown.Item>
  );
});

const Menu = React.forwardRef(({ onSearch, selectedSite }, ref) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 500);
  const isAdmin = useAuth().isAdmin;
  const [app] = useApp({ fullObject: true });
  const activeProduct = app?.parentId || app?.productId;
  const { setRef, size: getSize } = useResize();
  const listRef = useRef();
  const listOuterRef = useRef();

  useEffect(() => {
    setRef(listRef);
  }, []);
  const sitesQuery = api.useInfiniteQuery([
    'sites',
    {
      $select: ['siteId', 'name', 'url'],
      $join: { status: true },
      $limit: 100,
      $sort: { name: 1, url: 1 },
      ...(activeProduct !== 'nutshell' && { product: activeProduct }),
      active: true,
      url: { $ne: '', $notLike: 'app.nutshell.com/%' },
      ...(debouncedSearch
        ? {
            $or: [
              { url: { $like: `%${debouncedSearch}%` } },
              { name: { $like: `%${debouncedSearch}%` } },
            ],
          }
        : {}),
    },
  ]);

  const sortedSites = React.useMemo(() => {
    const sites = sitesQuery.data?.data ?? [];

    // If there's an active search, just filter results without prioritizing selected site
    if (debouncedSearch) {
      return sites.map((item) => ({
        item,
        isSelected: item.siteId === selectedSite?.siteId,
      }));
    }

    // Prevent duplicate selected item
    const otherItems = sites.filter((site) => site.siteId !== selectedSite?.siteId);

    return selectedSite
      ? // Selected site should be the first item
        [
          { item: selectedSite, isSelected: true },
          ...otherItems.map((item) => ({ item, isSelected: false })),
        ]
      : otherItems.map((item) => ({ item, isSelected: false }));
  }, [sitesQuery.data, selectedSite, debouncedSearch]);

  return (
    <div ref={ref} className={styles.menuWrapper}>
      <div className={styles.searchWrapper}>
        <Input
          className="mb-0"
          variant="sm"
          icon="search"
          iconPosition="left"
          placeholder={isAdmin ? 'Search for a Client' : 'Search for a Profile'}
          onChange={(e) => {
            setSearch(e.target.value);
            if (onSearch) {
              onSearch(e);
            }
          }}
          value={search}
          clearable
          onClear={() => setSearch('')}
          autoFocus
        />
      </div>
      {sitesQuery.isLoading ? (
        <div className="text-center py-3">
          <Spinner animation="border" data-fx-name="searchClientProfileSpinner" />
        </div>
      ) : (
        <List
          className="select-menu-items"
          outerRef={listOuterRef}
          innerElementType={({ children }) => <div>{children}</div>}
          ref={listRef}
          height={200}
          itemSize={getSize}
          itemCount={sortedSites.length}
          width="100%"
          itemData={{ ...sortedSites, selectedSiteId: selectedSite?.siteId }}
          estimatedItemSize={60}
        >
          {RowItem}
        </List>
      )}
    </div>
  );
});

export default Menu;
