import rcfxLogo from './assets/rcfxLogo.svg';
import adtechfxLogo from './assets/adtechfxLogo.svg';
import integrationfxLogo from './assets/integrationfxLogo.svg';
import slide1Image from './assets/loadingSlide1.svg';
import slide2Image from './assets/loadingSlide2.svg';
import slide3Image from './assets/loadingSlide3.svg';
import slide4Image from './assets/loadingSlide4.svg';
import slide5Image from './assets/loadingSlide5.svg';
import slide6Image from './assets/loadingSlide6.svg';
import slide7Image from './assets/loadingSlide7.svg';
import slide8Image from './assets/loadingSlide8.svg';

const slides = [
  {
    svg: {
      src: rcfxLogo,
      className: 'mcfxLogo',
      withText: '+Nutshell',
    },
    list: [
      'Leads generated from your website will automatically populate into Nutshell records',
      'Closed customers in Nutshell automatically sync back to RevenueCloudFX to allow us to report on every stage in the buying process from Click to Close',
    ],
  },
  {
    heading: 'Making Money Is Easier',
    desc: 'When Sales + Marketing Work Together',
  },
  {
    subHeading: 'Pipeline Management',
    heading: 'Keep track of every deal',
    desc: 'Nutshell offers the most flexible B2B sales tracking platform, offering multiple ways for sales reps to view, manage, and prioritize their leads.',
  },
  {
    subHeading: 'Sales Automation',
    heading: 'Automate the small stuff',
    desc: 'From automatic lead assignment and lead advancement to task reminders, Nutshell reduces busywork for managers and sellers so you can focus on building relationships.',
  },
  {
    subHeading: 'Reporting & Analytics',
    svg: {
      src: integrationfxLogo,
      className: 'integrationsfxLogo',
    },
    desc: 'Measure what matters, right out of the box. Nutshell’s fully customizable reports show sales managers what’s working and what needs to be improved.',
  },
  {
    subHeading: 'Nutshell Pipeline Retargeting',
    svg: {
      src: adtechfxLogo,
      className: 'adtechfxLogo',
      withText: '+Nutshell',
    },
    desc: 'Serve tailored ad content to those currently in your CRM sales pipeline as soon as the lead is created. Target these prospects across devices and channels, providing advertising “air cover” to your sales team.',
  },
  {
    subHeading: 'Email Sequences',
    heading: 'Get More Replies',
    desc: 'Put your sales outreach on autopilot. Nutshell’s personal email sequences remember the  follow-up for you and work in the background until you get a reply. busywork for managers and sellers so you can focus on building relationships.',
  },
  {
    subHeading: 'Reporting & Analytics',
    heading: 'Know where you stand',
    desc: 'Measure what matters, right out of the box. Nutshell’s fully customizable reports show sales managers what’s working and what needs to be improved.',
  },
];

const slideImages = [
  { image: slide1Image, contain: false },
  { image: slide2Image, contain: false },
  { image: slide3Image, contain: true },
  { image: slide4Image, contain: true },
  { image: slide5Image, contain: false },
  { image: slide6Image, contain: false },
  { image: slide7Image, contain: true },
  { image: slide8Image, contain: true },
];
export { slides, slideImages };
