import useCompany from './useCompany';

const HOME_AND_FACILITIES_KEYWORD = 'Home & Facility Services';

const useIsOutboundAndHomeServices = (companyId) => {
  const { data: company } = useCompany(companyId);

  const isHomeAndFacilitiesServicesIndustry = company?.rollupIndustry?.includes(
    HOME_AND_FACILITIES_KEYWORD
  );

  return isHomeAndFacilitiesServicesIndustry && company?.isOutbound;
};

export default useIsOutboundAndHomeServices;
