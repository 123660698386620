import { useFlags, usePermissions } from '@webfx/web-hooks';

/**
 * Determines if the user can view as another user.
 *
 * @param {Object} user - User object.
 * @returns {boolean} - True if the user can view as another user, false otherwise.
 *
 */
export default function useCanViewAs(user) {
  const permissions = usePermissions();
  const flags = useFlags();

  if (!user) {
    return false;
  }

  const active = user?.companiesUsers?.find((companyUser) => companyUser?.active === 1);

  const isLeadership = user?.teams?.some(({ name }) =>
    ['Leadership', 'General Leadership'].includes(name)
  );

  const requiresEncryption = user?.sites?.some((site) => site.encryption);

  const isEligibleAdmin =
    user?.type === 'admin' && (!['owner', 'admin'].includes(user?.type) || flags?.iamViewAsAdmin);

  const hasPermissions =
    permissions.can('RevenueCloudFX') ||
    permissions.can('Tech Enablement - TWFX') ||
    isEligibleAdmin;

  return active && !isLeadership && !requiresEncryption && hasPermissions;
}
