import { api } from '@webfx/core-web';
import useActiveSite from './useActiveSite';

/**
 * Returns the active sites Plan information
 * @param {boolean} useIamPlans - if true, will return plans from the new iam/plans endpoint
 * @param {string} siteId - if provided, will use this siteId instead of the active site
 * @param {object} query - the query object to pass to the api
 * @returns {object} - the active sites plan information
 */
export default function useSitePlan(useIamPlans = false, siteId = null, query = {}) {
  const { siteId: activeSiteId } = useActiveSite();
  const currentSiteId = siteId ?? activeSiteId;

  const { data: sitePlan, isLoading } = api.useQuery(
    ['sites', currentSiteId, { $join: ['plan'], $select: ['siteId'], $cache: false }],
    {
      enabled: !!currentSiteId && !useIamPlans,
      refetchOnWindowFocus: false,
    }
  );

  const { data: [iamPlan] = [], isLoading: iamIsLoading } = api.useQuery(
    ['/iam/plans', { siteId: currentSiteId, ...query }],
    {
      enabled: !!currentSiteId && useIamPlans,
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading || iamIsLoading) {
    return null;
  }

  return useIamPlans ? iamPlan : sitePlan?.plan;
}
