import { api, queryClient } from '@webfx/core-web/';
import { useCallback, useMemo } from 'react';
import { useActiveSite } from '@webfx/web-hooks/';
import { v4 as uuidv4 } from 'uuid';
import { castArray, isUndefined } from 'lodash';

/**
 * Custom hook to fetch ICPs.
 * @param {object} options - Options for the hook.
 * @param {boolean} [options.legacy] - Whether to use legacy mode - this will format the data to match the old ICP format.
 * @param {object} [options.params] - Additional parameters for the query.
 * @param {object} [reactQueryOpts] - Options for React Query.
 * @returns {object} - The result of the query.
 */
export default function useIcps({ legacy = false, ...params } = {}, reactQueryOpts = {}) {
  const { siteId } = useActiveSite();
  const key = [
    '/mcfx/icps',
    {
      siteId,
      $sort: { order: 1 },
      ...(legacy && { $limit: -1 }),
      ...params,
    },
  ];
  const icpsQuery = api.useQuery(key, {
    enabled: !!siteId,
    select: (data) => transformData(data, legacy),
    ...reactQueryOpts,
  });
  const queryKey = icpsQuery.queryKey;
  const mutator = api.useMutation(['/mcfx/icps'], {
    onMutate: async (_data) => {
      if (!queryKey) {
        return;
      }
      await queryClient.cancelQueries(queryKey);
      const data = castArray(_data?._multi || _data);
      const previousIcps = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, (_old) => {
        const old = _old?.data || _old;
        let updated = [];
        if (_data._method === 'remove') {
          const removedSet = new Set(data.map((d) => d.icpId || d._id));
          updated = old.filter((icp) => !removedSet.has(icp.icpId));
        } else if (_data._method === 'patch') {
          updated = old.map((icp) => {
            const updatedIcp = data.find((d) => (d.icpId || d._id) === icp.icpId);
            return updatedIcp || icp;
          });
        } else {
          updated = [...old, ...data];
        }
        return _old?.data ? { ..._old, data: updated } : updated;
      });
      return { previousIcps };
    },
    onError: (err, newIcp, context) => {
      queryClient.setQueryData(queryKey, context.previousIcps);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const removeIcp = useCallback(
    async (_id) => {
      const id = _id?.icpId || _id?.id || _id;
      if (!id) {
        throw new Error('ICP ID is required');
      }
      await mutator.mutateAsync({
        _method: 'remove',
        _id: id,
      });
    },
    [mutator]
  );

  const saveIcp = useCallback(
    async (_data) => {
      const icps = castArray(_data).map((data) => {
        const {
          icpId: _icpId,
          id,
          name,
          order,
          siteId: _siteId,
          config,
          createdAt,
          updatedAt,
          deletedAt,
          validForAdtech,
          validForCotfx,
          ...rest
        } = data; // need to format legacy data

        return {
          config: {
            ...rest,
            ...config,
          },
          siteId: _siteId || siteId,
          icpId: _icpId || id,
          name,
          order,
        };
      });
      const method = icps?.some((icp) => icp.icpId) ? 'patch' : 'create';
      if (method === 'patch') {
        await mutator.mutateAsync({
          _method: 'patch',
          ...(icps.length === 1
            ? {
                _id: icps[0].icpId,
                ...icps[0],
              }
            : { _multi: icps }),
        });
        return icps.length === 1 ? icps[0].icpId : icps.map((icp) => icp.icpId);
      }

      const saveData = icps.map((icp) => ({
        ...icp,
        icpId: uuidv4(),
      }));
      await mutator.mutateAsync({
        _method: 'create',

        ...(saveData.length === 1 ? saveData[0] : { _multi: saveData }),
      });

      return saveData.length === 1 ? saveData[0].icpId : saveData.map((icp) => icp.icpId);
    },
    [mutator, siteId]
  );

  const reorderIcps = useCallback(
    async (from, to) => {
      if (isUndefined(from) || isUndefined(to) || from === to || !icpsQuery.data) {
        return;
      }
      const data = [...icpsQuery.data];
      const [updated] = data.splice(from, 1);
      data.splice(to, 0, updated);
      const saveData = data?.map((icp, i) => ({
        ...icp,
        order: i + 1,
      }));
      saveIcp(saveData);
    },
    [icpsQuery, saveIcp]
  );

  const ret = useMemo(
    () => ({ ...icpsQuery, mutator, reorderIcps, removeIcp, saveIcp }),
    [icpsQuery, mutator, removeIcp, saveIcp, reorderIcps]
  );
  return ret;
}

const transformData = (data = [], legacy) => {
  if (!legacy) {
    return data;
  }
  return data
    ?.map(({ icpId, config, ...icp }) => ({
      id: icpId,
      ...icp,
      ...config,
    }))
    ?.sort((a, b) => a.order - b.order); // required to ensure sort is maintained after reordering
};
