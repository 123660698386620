import React, { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ResizeProvider } from '@webfx/web-context';
import { Icon } from '@webfx/core-web';
import { useActiveSite, useAuth, useRouter } from '@webfx/web-hooks';

import { useStoreActions } from 'easy-peasy';

import Toggle from './Toggle';
import Menu from './Menu';

import styles from './SiteSelector.module.css';

export const SiteSelector = ({ variant }) => {
  const { isAdmin } = useAuth();
  const { setActive } = useStoreActions((actions) => actions.sites);
  const activeSite = useActiveSite({ $join: ['encryption'] });
  const { url } = useRouter();
  const history = useHistory();

  const toggleLabel = useMemo(() => {
    if (activeSite.siteId === null) {
      return isAdmin ? 'Select a Client' : 'Select a Profile';
    }
    return activeSite.name ?? activeSite.url;
  }, [activeSite, isAdmin]);

  return (
    <div id="container-div" className="1 flex-grow-1">
      <Dropdown>
        <Dropdown.Toggle as={Toggle} variant={variant} data-fx-name="selectClientProfile">
          <span className={styles.toggleLabel} data-fx-name="currentClientProfile">
            {toggleLabel}
          </span>
          {activeSite.siteId ? (
            <div
              className={styles.close}
              onClick={() => {
                setActive({ siteId: null });
                history.push(url({ route: 'mcfxDashboard' }));
              }}
            >
              <Icon>close</Icon>
            </div>
          ) : null}
        </Dropdown.Toggle>
        <ResizeProvider>
          <div className={styles.dropdownMenu}>
            <Dropdown.Menu>
              <Menu selectedSite={activeSite} />
            </Dropdown.Menu>
          </div>
        </ResizeProvider>
      </Dropdown>
    </div>
  );
};
