import React from 'react';
import { Link } from 'react-router-dom';
import { useResize } from '@webfx/web-context';
import { useRouter, useApp, useLayout } from '@webfx/web-hooks';
import classnames from 'classnames';
import { useStoreActions } from 'easy-peasy';

import styles from './DropdownItem.module.css';

const DropdownItem = React.forwardRef(
  ({ href = '', children, data, index, style, activeSite, onClick, ...passThroughProps }, ref) => {
    const { item } = data;
    const { url, active, params } = useRouter();
    const [app] = useApp();

    const setActiveSiteId = useStoreActions((actions) => actions.sites.setActive);
    const { retainParams } = useLayout();
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    const { setSize } = useResize();

    React.useEffect(() => {
      if (resolvedRef.current) {
        setSize({
          id: index,
          size: resolvedRef.current.getBoundingClientRect().height,
        });
      }
    }, [index, resolvedRef, setSize]);

    let routeName = active;
    if (app === 'cgfx') {
      routeName = 'cgfxKanban';
    } else if (active === 'crmContact') {
      routeName = 'crmContacts';
    }

    // Update store state immediately to avoid race conditions
    const handleClick = () => {
      setActiveSiteId(item.siteId);
    };

    return (
      <div ref={resolvedRef} style={style} onClick={onClick}>
        <Link
          to={
            href ||
            url({
              route: routeName,
              ...(retainParams ? params : {}),
              siteId: item.siteId,
            })
          }
          role="button"
          {...passThroughProps}
          key={`site-${item.siteId}`}
          data-fx-name="menuItems"
          className={classnames('d-block py-2 px-3', styles['site-select-item'], styles.link, {
            [styles.active]: activeSite,
          })}
          onClick={handleClick}
        >
          <h4 className={styles.name}>{item.name ?? item.url}</h4>
          <p className={styles.url}>{item.url.replace(/^http(s)?:\/\/(www\.)?/, '')} </p>
        </Link>
      </div>
    );
  }
);

export default DropdownItem;
