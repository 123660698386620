import React from 'react';
import { Tab as HeadlessTab } from '@headlessui/react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import { matchPath } from 'react-router';
import { useAuth, useSiteSubproducts } from '@webfx/web-hooks';
import styles from './Tab.module.css';

const InternalLink = React.forwardRef(
  ({ to, children, 'aria-selected': selected, className, ...passThroughProps }, ref) => {
    return (
      <NavLink
        ref={ref}
        to={to}
        isActive={(match) => {
          return !!match?.isExact;
        }}
        {...passThroughProps}
        // this line hacks internal links to remove any sort of default selection
        // and rely on NavLink to provide Selected state
        className={(selected) => (!selected ? className.replace(styles.selected, '') : className)}
      >
        {children}
      </NavLink>
    );
  }
);

const ExternalLink = React.forwardRef(
  ({ to, children, 'aria-selected': selected, className, ...passThroughProps }, ref) => {
    const internalHosts = ['app.webfx.com', 'app.marketingcloudfx.com'];
    const toHost = new URL(to).host;
    const isExternal = !internalHosts.includes(toHost);

    return (
      // eslint-disable-next-line react/jsx-no-target-blank -- this is a link to an external site
      <a
        ref={ref}
        href={to}
        target={isExternal ? '_blank' : undefined}
        rel={isExternal ? 'noreferrer' : undefined}
        className={className}
        {...passThroughProps}
      >
        {children}
      </a>
    );
  }
);

function Tab({ to, underline = true, children, className, ...passThroughProps }) {
  const { user } = useAuth();
  const siteSubproducts = useSiteSubproducts();
  const allMcfxSubproducts = user?.products.filter((p) => p.parentId === 'mcfx'); // Every user's products list has an entry for every product, even if they don't have access to it.
  if (
    allMcfxSubproducts.some((p) => matchPath(to, p.matchPath)) &&
    !siteSubproducts.some((p) => matchPath(to, p.matchPath))
  ) {
    return null; // Hide tabs that link to MCFX subproducts the user does not have access to.
  }

  if (to) {
    typeof to === 'string' && to.startsWith('http')
      ? (passThroughProps.as = ExternalLink)
      : (passThroughProps.as = InternalLink);
  }

  return (
    <HeadlessTab
      to={to}
      className={({ selected }) =>
        classnames(
          styles.tab,
          { [styles.selected]: selected, [styles.underline]: underline },
          'font-14 bg-transparent',
          className
        )
      }
      {...passThroughProps}
    >
      {children}
    </HeadlessTab>
  );
}

export default Tab;
