import { DateTime } from 'luxon';
import { api } from '@webfx/core-web';

import { useActiveSite } from '@webfx/web-hooks';

// eslint-disable-next-line workspaces/no-cross-imports -- forcing the input for now
import useCompanySites from '@webfx/teamwork-web/src/hooks/useCompanySites';

const useHistoryStats = (companyId, dateFilter = {}) => {
  const { siteId: activeSiteId } = useActiveSite();
  const { data: company } = useCompanySites(companyId);
  const siteId = activeSiteId || company?.sites[0]?.siteId;

  const results = api.useQuery(
    [
      'tw/history/stats',
      {
        companyId,
        ...(dateFilter.startDate && { startDate: dateFilter.startDate }),
        ...(dateFilter.endDate && { endDate: dateFilter.endDate }),
      },
    ],
    {
      enabled: !!companyId,
    }
  );

  const leadsDriven = api.useQuery(
    [
      '/impressions/metrics',
      {
        siteId,
        $agg: 'count',
        $group: 'entity',
        $subGroup: 'channel',
        'entity[$in]': ['form', 'call'],
        'status[$ne]': 'spam',
        ...(dateFilter.startDate && {
          'date[$gte]': DateTime.fromISO(dateFilter.startDate).toUTC().toISO(),
        }),
        ...(dateFilter.endDate && {
          'date[$lte]': DateTime.fromISO(dateFilter.endDate).endOf('day').toUTC().toISO(),
        }),
      },
    ],
    {
      enabled: !!siteId,
    }
  );

  return {
    ...results,
    leadsDriven: {
      forms: leadsDriven?.data?.results?.find((r) => r.name === 'form')?.value || 0,
      calls: leadsDriven?.data?.results?.find((r) => r.name === 'call')?.value || 0,
    },
  };
};

export default useHistoryStats;
