import React from 'react';
import { useActiveSite, useAuth } from '@webfx/web-hooks';

import ViewAsSelector from './ViewAsSelector';
import { SiteSelector } from './SiteSelector';

export const MenuSiteSelector = ({ variant, ...passThroughProps }) => {
  const { isAdmin, user } = useAuth();
  const activeSite = useActiveSite({ $join: ['encryption'] });

  const { encryption: requiresEncryption } = activeSite;

  if (!isAdmin && user?.sites?.length <= 1) {
    return null;
  }

  const showViewAsSelector =
    activeSite.siteId !== null &&
    activeSite.companyId &&
    activeSite.companyId !== 373296 &&
    isAdmin &&
    !requiresEncryption;

  return (
    <div {...passThroughProps}>
      <div className="d-flex">
        <SiteSelector variant={variant} />
        {showViewAsSelector ? <ViewAsSelector variant={variant} /> : null}
      </div>
    </div>
  );
};
