import { useMemo } from 'react';
import { useActiveSite, useAuth } from '@webfx/web-hooks';

/**
 * Returns an array of product objects for the subproducts that the active user has access to on the active site.
 * The order is: CDP, then top-down as shown on the sidebar menu.
 * @returns {object[]}
 */
export default function useSiteSubproducts() {
  const { user } = useAuth();
  const { siteId } = useActiveSite();
  const products = user?.products;

  return useMemo(() => {
    if (!products || !siteId) {
      return [];
    }
    return products.filter(
      (p) =>
        (p.parentId === 'mcfx' || p.productId === 'analytics') &&
        ((!p.disabled && (p.siteIds.includes(siteId) || p.siteIds.length === 0)) || // Product enabled for sites in its siteIds list (empty list means enabled for all sites)
          (p.disabled && !p.siteIds.includes(siteId) && p.siteIds.length !== 0)) // Product disabled for sites in its siteIds list (empty list means disabled for all sites)
    );
  }, [products, siteId]);
}
