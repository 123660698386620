import { api } from '@webfx/core-web';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';

/**
 * Custom hook for fetching company data.
 * @param {number} companyId - The ID of the company.
 * @param {object} queryParams - Additional query parameters.
 * @param {object} reactQueryParams - Additional parameters for react-query.
 * @returns {object} - An object containing the company data, query parameters, and mutator.
 */
export default function useCompany(companyId, queryParams = {}, reactQueryParams = {}) {
  const params = useParams();
  const $join =
    get(queryParams, 'query.$join') ?? get(queryParams, '$join', ['status', 'qbo', 'settings']);

  const _companyId = companyId ?? params.id ?? null;
  const _queryParams = !queryParams.query ? { query: queryParams } : queryParams;

  const { data, ...passThroughQuery } = api.useQuery(
    [
      'companies',
      Number(_companyId),
      {
        ..._queryParams,
        query: {
          $join,
          ..._queryParams.query,
        },
      },
    ],
    {
      enabled: !!_companyId,
      select(data) {
        if (data.settingsMap) {
          return data;
        }

        const settingsMap = new Map();
        const settings = (data.settings ?? []).reduce((accum, setting) => {
          settingsMap.set(setting.field, setting.companySettingId);
          accum.set(setting.field, setting.value);
          return accum;
        }, new Map());

        return { ...data, settings, settingsMap };
      },
      ...reactQueryParams,
    }
  );

  const mutator = api.useMutation(['companies', _companyId], 'companyId', [
    passThroughQuery.queryKey,
  ]);

  return {
    data,
    ...passThroughQuery,
    mutator,
  };
}
