import React from 'react';
import styles from './Toggle.module.css';

const Toggle = React.forwardRef(({ children, variant, ...passThroughProps }, ref) => {
  return (
    <div ref={ref} {...passThroughProps} className={`${styles.wrapper} ${styles[variant]}`}>
      {children}
    </div>
  );
});

export default Toggle;
