import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { useAuth } from '@webfx/web-hooks';
import { api } from '@webfx/core-web';

/**
 * Return what the current in use app is.
 * @param {string} path - The path to match against, defaults to the current path if not provided.
 * @param {boolean} fullObject - Whether to return the full object or just the app ID.
 * @returns {string}
 */
export default function useApp({ path, fullObject = false } = {}) {
  const { user } = useAuth();
  const { pathname: currentPath } = useLocation();
  const pathname = path ?? currentPath;
  const queryEnabled = user?.userId && user?.products?.[0].matchPath === null;
  const userApps = api.useQuery(
    ['users', user?.userId, { $select: ['userId'], $join: ['products'] }],
    {
      enabled: queryEnabled,
      cacheTime: 60 * 1000,
    }
  );

  const apps = user?.products?.[0].matchPath ? user?.products : userApps?.data?.products ?? [];
  const isLoading = queryEnabled && userApps.isLoading;

  const matches = apps.reduce((matches, curr) => {
    if (curr.matchPath && matchPath(pathname, curr.matchPath)) {
      matches.push(curr);
    }
    return matches;
  }, []);

  matches.sort((a, b) => {
    // Subproducts should be sorted before their parent.
    if (a.parentId === b.productId) {
      return -1;
    }
    if (b.parentId === a.productId) {
      return 1;
    }
    const aSegments = a.matchPath.split('/').length;
    const bSegments = b.matchPath.split('/').length;
    return bSegments - aSegments;
  });

  const activeApp = fullObject ? matches?.[0] : matches?.[0]?.productId;

  return useMemo(() => [activeApp, isLoading], [activeApp, isLoading]);
}
