import { useCallback } from 'react';

import { api, queryClient } from '@webfx/core-web';
import useActiveSite from './useActiveSite';
import useAuth from './useAuth';

/**
 * @typedef {object} NutshellCreateInstance
 * @property {Function} create - The create function creates a Nutshell instance using active site
 * @property {boolean} isProvisioning - isProvisioning is set to true when a Nutshell instance is being created and set to false when the process is done
 * @property {boolean} isProvisioned - isProvisioned is set to true when an active site already has a Nutshell instance.
 * @property {object} data - Data is an object with keys nsInstanceId (Nutshell instance id)and nsActive (Nutshell site active status )
 */

/**
 * This hook returns the below object
 * @param {*} params
 * @returns {NutshellCreateInstance}
 */
const useCreateNutshellInstance = (params) => {
  const site = useActiveSite({ $cache: false });
  const { user } = useAuth();
  const nutshellMutator = api.useMutation(['nutshell'], {
    ...params,
    onSuccess: async () => {
      const nutshellData = await queryClient.fetchQuery([`nutshell`, site.siteId]);
      if (nutshellData.nsInstanceId) {
        queryClient.setQueryData([`users/${user.userId}/nutshell`, { site: site.siteId }], {
          nsAuthToken: nutshellData.auth,
        });
      }

      return Promise.all([
        queryClient.invalidateQueries('nutshell/auth'),
        queryClient.invalidateQueries(`users/${user.userId}/nutshell`),
        queryClient.invalidateQueries('sites'),
      ]);
    },
  });

  const data = {
    ...(site?.nsInstanceId && {
      nsInstanceId: site?.nsInstanceId,
      nsActive: true,
    }),
    ...(nutshellMutator.isError && {
      error: nutshellMutator.error,
    }),
  };
  const isProvisioned = data.nsInstanceId;
  const create = useCallback(async () => {
    if (isProvisioned) {
      throw new Error('Nutshell instance has already been created');
    }
    await nutshellMutator.mutateAsync({
      _method: 'create',
      siteId: site.siteId,
    });
  }, [isProvisioned, site.siteId, nutshellMutator]);

  return {
    create,
    isProvisioning: nutshellMutator.isLoading,
    isProvisioned,
    isErrored: nutshellMutator.isError,
    isLoading: site.isLoading,
    data,
    isCreating: nutshellMutator.isLoading,
  };
};

export default useCreateNutshellInstance;
