import React, { useRef } from 'react';
import {
  useCreateNutshellInstance,
  useNotifications,
  useNutshellAuth,
  useOnloadPostMessage,
} from '@webfx/web-hooks';
import { ErrorPage, Spinner } from '@webfx/core-web';
import styles from './NutshellEmbedFrame.module.css';

/**
 *  @param {object} props - props
 * @param {string} props.token - Nutshell Auth Token
 * @param {string} props.embedURL - embedURL
 * @returns jsx markup
 */
export const NutshellEmbed = ({ token, embedURL }) => {
  const iframeRef = useRef(null);

  useOnloadPostMessage({
    ref: iframeRef,
    message: token,
    targetOrigin: 'https://nutshell.webfx.com/',
  });

  return (
    <div className={styles.container}>
      <iframe
        className={styles.frame}
        ref={iframeRef}
        src={embedURL}
        allow="clipboard-read; clipboard-write"
      ></iframe>
    </div>
  );
};

/**
 * @param {object} props - props
 * @param {string} props.embedURL - embedURL
 * @param {string} props.siteId - siteId
 * @param {string} props.nsInstanceId - nsInstanceId
 * @param { React.Component | null | undefined } props.loadingComponent - loadingComponent
 * @returns {React.Component} jsx markup
 */
export const NutshellEmbedFrame = ({
  embedURL,
  siteId = null,
  nsInstanceId = null,
  loadingComponent = <div></div>,
}) => {
  const nutshell = useCreateNutshellInstance();
  const auth = useNutshellAuth({ targetSiteId: siteId, authFor: 'embed' });
  const { toast } = useNotifications();
  const isErrorNotificationShown = useRef(false);

  if (nutshell.isLoading || auth.isLoading) {
    return <Spinner />;
  }

  if (!nutshell?.data?.nsInstanceId && !nsInstanceId) {
    return loadingComponent;
  }

  const { token } = auth?.data || {};

  if (!auth.isFetching && auth.isFetched && !token) {
    if (!isErrorNotificationShown.current) {
      toast.error(
        'Your Nutshell account is not available. Please contact your account manager for assistance.'
      );
      isErrorNotificationShown.current = true;
    }

    return (
      <ErrorPage title="404" subtitle="Your Nutshell account is not available">
        <p>Please contact your account manager for assistance.</p>
      </ErrorPage>
    );
  }

  if (!token) {
    return loadingComponent;
  }

  return <NutshellEmbed token={token} embedURL={embedURL} />;
};
