import { api } from '@webfx/core-web';
import { useFlags } from '@webfx/web-hooks';
import { isObject, isString } from 'lodash';
import { useIcps } from '@webfx/web-hooks';
import useActiveSite from './useActiveSite';
import useParamFilters from './useParamFilters';

/**
 * Hook for auto prospect functionality.
 * @param {object} passThroughFilters - The pass-through filters.
 * @param {object} options - The options.
 * @returns {object} - The result object.
 */
export default function useAutoProspect(passThroughFilters = {}, options = {}) {
  const flags = useFlags();
  const { siteId } = useActiveSite();
  const {
    query: { filters = {} },
  } = useParamFilters();

  const { data: icps = [] } = useIcps({ legacy: true });
  const icpsIndustries = icps
    .reduce((acc, icp) => {
      if (icp.industry?.length > 0 && !icp.excludeindustry) {
        acc.push(icp.industry);
      }
      return acc;
    }, [])
    .flat();

  let industries = filters.industry?.$in || [];
  if (industries.length === 0 && filters.industry?.value) {
    industries.push(filters.industry);
  }
  if (flags.prospectorAiIcpIndustryRestriction && !filters.excludes?.$in.includes('industry')) {
    industries = industries.concat(icpsIndustries);
  }
  filters.industry = { $in: [...new Map(industries.map((item) => [item.value, item])).values()] };

  const tags = getTagValues(filters);
  const restricts = getRestricts(tags);
  const queryKey = [
    'idp',
    'auto-prospect',
    {
      siteId,
      filters: tags,
      restricts,
      ...passThroughFilters,
      $join: ['fxContact'],
    },
  ];
  const person = api.useQuery(queryKey, {
    ...options,
    staleTime: Infinity, // disable refetching and only query when the query key changes
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
  return { ...person, queryKey };
}

function getTagValues(filters = {}) {
  return Object.entries(filters).reduce((_acc, [key, tags]) => {
    const acc = _acc;
    if (tags.value) {
      acc[key] = tags.value;
    }
    if (Array.isArray(tags)) {
      const tagValues = tags.map((t) => t.value || t);
      acc[key] = tagValues;
    }
    if (tags['$in']) {
      const tagValues = tags['$in'].map((tag) => {
        if (Array.isArray(tag)) {
          return tag.map((t) => t.value || t);
        }
        return tag.value || tag;
      });
      acc[key] = tagValues.toString() || [];
    } else if (isObject(tags) && ('gte' in tags || 'lte' in tags)) {
      acc[key] = {
        ...(tags.gte ? { $gte: tags.gte } : {}),
        ...(tags.lte ? { $lte: tags.lte } : {}),
        $relation: 'WITHIN',
      };
    }

    if (acc[key]?.$in) {
      acc[key].$in = acc[key]?.$in?.map((value) => (isString(value) ? value.toLowerCase() : value));

      // quick fix for terms with spaces in them. This should be removed once we have a better solution
      // for handling terms with spaces - This treats the entire array as a single term and searches for it
      // this was needed because $in queries are treated as terms searches
      const stringQuries = ['fullName', 'name', 'jobCompanyName', 'education.school.name'];
      if (stringQuries.includes(key)) {
        acc[key] = acc[key].$in.join(' ');
      }
    }
    return acc;
  }, {});
}

function getRestricts(tags = {}) {
  return Object.entries(tags)
    .map(([key, value]) => {
      if (typeof value === 'string') {
        return {
          namespace: key,
          allowList: value.split(','),
        };
      }
    })
    .filter((r) => r);
}
