import { useCallback } from 'react';
import { api } from '@webfx/core-web';

/**
 * Returns functions for comparing the relative tiers of billing plans.
 * Should be kept in sync with the plans cloud-util.
 * @returns {object}
 */
const usePlanComparison = () => {
  const { data: plans, isLoading: isPlansLoading } = api.useQuery(['/plans-new', { $limit: -1 }], {
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000,
  });

  const getPlanOrders = useCallback(
    (planIdA, planIdB) => {
      const planOrderMap = Object.fromEntries(plans.map((plan) => [plan.planId, plan.order]));
      if (!Object.hasOwn(planOrderMap, planIdA)) {
        throw new Error(`Plan ID '${planIdA}' not found`);
      }
      if (!Object.hasOwn(planOrderMap, planIdB)) {
        throw new Error(`Plan ID '${planIdB}' not found`);
      }
      return [planOrderMap[planIdA], planOrderMap[planIdB]];
    },
    [plans]
  );

  if (isPlansLoading) {
    return {
      isPlanGreaterThan: null,
      isPlanGreaterThanOrEqualTo: null,
      isPlanLessThan: null,
      isPlanLessThanOrEqualTo: null,
      isLoading: true,
    };
  }

  return {
    isPlanGreaterThan: (planIdA, planIdB) => {
      const [planOrderA, planOrderB] = getPlanOrders(planIdA, planIdB);
      return planOrderA > planOrderB;
    },
    isPlanGreaterThanOrEqualTo: (planIdA, planIdB) => {
      const [planOrderA, planOrderB] = getPlanOrders(planIdA, planIdB);
      return planOrderA >= planOrderB;
    },
    isPlanLessThan: (planIdA, planIdB) => {
      const [planOrderA, planOrderB] = getPlanOrders(planIdA, planIdB);
      return planOrderA < planOrderB;
    },
    isPlanLessThanOrEqualTo: (planIdA, planIdB) => {
      const [planOrderA, planOrderB] = getPlanOrders(planIdA, planIdB);
      return planOrderA <= planOrderB;
    },
    isLoading: false,
  };
};

export default usePlanComparison;
