import { createStore } from 'zustand-x';
import castArray from 'lodash/castArray';

export const MenuStore = createStore('menu')(
  {
    menuOpen: true,
    openSubMenus: [],
    prevOpenSubMenus: [],
    canGoBack: true,
  },
  {
    persist: {
      enabled: true,
    },
  }
)
  .extendSelectors((state, get) => ({
    activeSubMenu: () => (get.openSubMenus().length ? get.openSubMenus().at(-1) : null),
  }))
  .extendActions((set, get) => ({
    activeSubMenu: (newSubMenu) => {
      const prevSubMenu = get.activeSubMenu();
      const openSubMenus = get.openSubMenus();
      set.prevOpenSubMenus(openSubMenus);

      if (!newSubMenu) {
        // Returning to the parent menu
        set.openSubMenus([]);
      } else if (!prevSubMenu) {
        // Opening a new submenu
        set.openSubMenus([newSubMenu]);
      } else if (!openSubMenus.includes(newSubMenu)) {
        // Opening a sub-submenu
        set.openSubMenus([...openSubMenus, newSubMenu]);
      } else if (openSubMenus.includes(newSubMenu)) {
        // Closing a sub-submenu
        set.openSubMenus(openSubMenus.filter((subMenu) => subMenu !== prevSubMenu));
      }
    },
    // Okay to have the same name here
    openSubMenus: (newOpenSubMenus) => {
      set.prevOpenSubMenus(get.openSubMenus());
      set.openSubMenus(castArray(newOpenSubMenus));
    },
  }))
  .extendActions((set, get) => ({
    goBack: () => {
      if (get.openSubMenus().length > 1) {
        set.activeSubMenu(get.openSubMenus().at(-2));
      } else {
        set.activeSubMenu(null);
      }
    },
  }));
