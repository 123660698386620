import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import classnames from 'classnames';

import styles from './SlidePane.module.css';

/**
 * @param {object} root0
 * @param {string} root0.headerContent
 * @param {string} root0.headerClass
 * @param {React.ReactNode} root0.children
 * @param {boolean} root0.show
 * @param {boolean} root0.showCloseBtn
 * @param {Function} root0.onClose
 * @param {boolean} root0.backdrop
 * @param {string} root0.contentClassName
 * @param {string} root0.dialogClassName
 * @param {string} root0.bodyClass
 * @param {('left'|'right')} root0.position
 * @param {('small'|'large')} root0.modalSize
 * @returns {React.JSX.Element} The SlidePane component
 */
const SlidePane = ({
  headerContent = '',
  headerClass = '',
  children,
  show = false,
  showCloseBtn = true,
  onClose = () => {},
  backdrop = true,
  contentClassName = '',
  dialogClassName = '',
  bodyClass = '',
  position = 'right',
  modalSize = 'small',
}) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop={backdrop}
      keyboard={false}
      className={classnames(styles.slidePane, {
        [styles.right]: position === 'right',
        [styles.left]: position === 'left',
      })}
      contentClassName={contentClassName}
      dialogClassName={classnames(dialogClassName, {
        [styles.lg]: modalSize === 'large',
      })}
    >
      {headerContent ? (
        <ModalHeader closeButton={showCloseBtn} className={headerClass}>
          {headerContent}
        </ModalHeader>
      ) : null}
      <ModalBody className={bodyClass}>{children}</ModalBody>
    </Modal>
  );
};

export default SlidePane;
