import React from 'react';
import { Icon } from '@webfx/core-web';
import styles from './Toggle.module.css';

const Toggle = React.forwardRef(({ children, variant, ...passThroughProps }, ref) => {
  return (
    <div ref={ref} {...passThroughProps} className={`${styles.wrapper} ${styles[variant]}`}>
      <div className={styles.children}>{children}</div>
      <Icon className="text-primary-light-gray font-24 ml-1">keyboard_arrow_down</Icon>
    </div>
  );
});

export default Toggle;
