import { useEffect, useRef } from 'react';
import { LookerEmbedSDK } from '@looker/embed-sdk';
import { LOOKER_EMBED_HOST } from '@webfx/marketingcloud-web/src/config/constants';
import { useActiveSite } from '@webfx/web-hooks';
import { isEmpty } from 'lodash';
import styles from './Looker.module.css';

const API_URL = process.env.FX_APP_API ? process.env.FX_APP_API : process.env.POI_APP_API;

/**
 * A React component to embed Looker dashboards into your application.
 * @param {object} props - The props object.
 * @param {number} props.dashboardId - The ID of the Looker dashboard to embed.
 * @param {string} props.divId - The ID of the div element where the dashboard should be embedded.
 * @param {Array<object>} [props.params] - An array of parameters to be passed to the Looker dashboard.
 * @param {Object} [props.lookerParams] - Additional parameters for configuring the Looker dashboard. https://cloud.google.com/looker/docs/themes-for-embedded-dashboards-and-explores#theme
 * @param {Object} [props.lookerFilters] - Filters to apply to the Looker dashboard.
 * @returns {JSX.Element} A React component to embed Looker dashboards.
 * @example
 * // Example usage:
 * const dashboardParams = [{ name: 'siteId', value: siteId, Date: "24+month", MROI_Channel: "", Form_Name: "" }];
 * <Looker divId="leads-events" dashboardId={112} params={dashboardParams} lookerParams={{ _theme: '{"show_title":false}' }} />;
 */
function Looker({ dashboardId, divId, params, lookerParams, lookerFilters }) {
  const { siteId } = useActiveSite();
  const dashboardRef = useRef(null);

  useEffect(() => {
    if (!dashboardRef.current) {
      return;
    }

    const dashboardParams = params || [{ name: 'siteId', value: siteId }];
    const currentDashboardRef = dashboardRef.current;

    currentDashboardRef.innerHTML = '';

    LookerEmbedSDK.init(LOOKER_EMBED_HOST, {
      url: `${API_URL}/looker-embed-auth`,
      params: dashboardParams,
      headers: [{ name: 'Authorization', value: localStorage.getItem('accessToken') }],
    });

    const dashboard = LookerEmbedSDK.createDashboardWithId(dashboardId);
    if (!isEmpty(lookerFilters)) {
      dashboard.withFilters(lookerFilters);
    }
    dashboard
      .appendTo(currentDashboardRef)
      .withParams(lookerParams)
      .build()
      .connect()
      .catch((error) => {
        console.error('An unexpected error occurred', error);
      });
    return () => {
      currentDashboardRef.innerHTML = ''; // Remove embedded dashboard on unmount
    };
  }, [
    dashboardId,
    divId,
    siteId,
    JSON.stringify(params),
    JSON.stringify(lookerParams),
    JSON.stringify(lookerFilters),
  ]);

  return (
    <div
      id={divId}
      ref={dashboardRef}
      data-fx-name={divId + 'Dashboard'}
      className={styles.lookerDashboard}
    ></div>
  );
}

export default Looker;
