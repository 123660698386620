import { createStore } from 'zustand-x';

const defaults = {
  route: null,
  logo: null,
  copy: null,
  hideSelector: false,
  retainParams: false,
};

export const LayoutStore = createStore('layout')({
  banner: {
    ...defaults,
  },
  modal: {
    showModal: false,
  },
})
  .extendSelectors((state, get) => ({
    modal: () => get.modal(),
  }))
  .extendActions((set, get) => ({
    setBanner: (payload) => {
      set.banner(
        payload
          ? {
              ...get.banner(),
              ...payload,
              ...(!payload.route && { route: null }),
              ...(!payload.hideSelector && { hideSelector: false }),
              ...(!payload.retainParams && { retainParams: false }),
            }
          : defaults
      );
    },
    setShowModal: (showModal) => {
      set.modal({
        showModal,
      });
    },
  }));
