import useMediaQuery from './useMediaQuery';
export function useDeviceSize() {
  const isMobile = useMediaQuery('(max-width:767px)');
  const isTablet = useMediaQuery('(min-width:768px) and (max-width:991px)');

  return {
    isMobile,
    isTablet,
    isLaptop: !isMobile && !isTablet,
  };
}
