import { actions, useStore } from '@webfx/web-state';

/**
 * Returns the menu state information
 * @returns {object}
 */
export default function useMenu() {
  const store = useStore();
  return {
    // state
    activeSubMenu: store.menu.activeSubMenu(),
    canGoBack: store.menu.canGoBack(),
    menuOpen: store.menu.menuOpen(),
    openSubMenus: store.menu.openSubMenus(),
    prevOpenSubMenus: store.menu.prevOpenSubMenus(),

    // actions
    goBack: actions.menu.goBack,
    setActiveSubMenu: actions.menu.activeSubMenu,
    setCanGoBack: actions.menu.canGoBack,
    setMenuOpen: actions.menu.menuOpen,
    setOpenSubMenus: actions.menu.openSubMenus,
  };
}
