import React from 'react';
import { Icon, Link, Popover } from '@webfx/core-web';
import { ResizeProvider } from '@webfx/web-context';
import { useAuth } from '@webfx/web-hooks';
import classnames from 'classnames';
import { Dropdown } from 'react-bootstrap';
import Menu from './Menu/Menu';

import Toggle from './Toggle';
import styles from './ViewAsSelector.module.css';

export default function ViewAsSelector() {
  const { user: currentUser = {}, loggedInUser = {} } = useAuth();

  const toggleLabel = React.useMemo(() => {
    if (currentUser?.userId === loggedInUser?.userId) {
      return 'View as...';
    }
    return (
      <>
        Viewing as{' '}
        <span className={classnames('text-blue-vivid-400', 'font-weight-bold')}>
          {currentUser?.firstName} {currentUser?.lastName}
        </span>
      </>
    );
  }, [currentUser, loggedInUser]);

  return (
    <div className="d-flex justify-content-end pt-1">
      <Popover
        triggerText={<Icon className="text-blue-vivid-200 font-22">help</Icon>}
        trigger={'click'}
        placement={'bottom'}
        triggerClassName="d-flex align-items-center pr-1"
        content={
          <>
            <div className="pb-2">
              See what your client can access in RevenueCloudFX by selecting their name from the
              dropdown.
            </div>
            <div>
              Or go to <Link to="/client-permissions/users">global permissions</Link> and click
              “view as...” from the user table.
            </div>
          </>
        }
      />
      <Dropdown className={styles.viewAsSelector}>
        <Dropdown.Toggle as={Toggle} data-fx-name="viewAs">
          <span
            className="flex-grow-1 text-left user-select-none"
            data-fx-name="currentClientProfile"
          >
            {toggleLabel}
          </span>
        </Dropdown.Toggle>
        <ResizeProvider>
          <div className=" position-relative">
            <div className={classnames('bg-danger position-absolute', styles.menuOuter)}>
              <Dropdown.Menu className={styles.menuWrapper}>
                <Menu />
              </Dropdown.Menu>
            </div>
          </div>
        </ResizeProvider>
      </Dropdown>
    </div>
  );
}
