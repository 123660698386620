import useUser from '@webfx/permissions-web/src/hooks/useUser';
import useAuth from './useAuth';

/**
 * Checks the current authenticated user if they are a C-Level user
 * @returns Boolean
 */
export default function useIsCLevelUser() {
  const { userId } = useAuth();
  const { data: user, isLoading } = useUser(userId);
  const { title, email } = user || {};

  const isCLevelUser = ({ title, email }) => {
    if (email.includes('@webfx.com') || !title) {
      return false;
    }
    const normalizedTitle = title.toLowerCase();
    if (
      ['ceo', 'coo', 'cfo', 'cmo'].includes(normalizedTitle) ||
      ['chief', 'vp', 'owner', 'general manager', 'president', 'founder', 'partner'].some((t) =>
        normalizedTitle.includes(t)
      )
    ) {
      return true;
    }
    return false;
  };

  return isLoading ? false : isCLevelUser({ title, email });
}
