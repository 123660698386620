import { actions, useTrackedStore } from '@webfx/web-state';

/**
 * Returns the authentication information
 *
 * @returns {object}
 */
export default function useAuth() {
  const store = useTrackedStore();
  return {
    // state
    user: store.auth.user(),
    userId: store.auth.user()?.userId ?? null,
    isAdmin: store.auth.isAdmin() ?? false,
    isGuest: store.auth.isGuest() ?? false,
    accessToken: store.auth.accessToken(),
    loggedInUser: store.auth.loggedInUser(),
    loginTime: store.auth.loginTime(),
    // actions
    login: actions.auth.login,
    logout: actions.auth.logout,
    loginAsUser: actions.auth.loginAsUser,
    switchBackToLoggedInUser: actions.auth.switchBackToLoggedInUser,
    setUser: actions.auth.user,
  };
}
