import { isEmpty } from 'lodash';
import { api } from '@webfx/core-web';
import { DateTime } from 'luxon';
import { normalizePersonFilters } from '@webfx/formatting';
import useActiveSite from './useActiveSite';
import useParamFilters from './useParamFilters';
import useFlags from './useFlags';
/**
 * Queries the idp/person endpoint with the current siteId
 * and query params based on the current route
 * @param {object} params
 * @param {object} params.passThroughFilters additional filters to apply to the query
 * @param {object} options additional options to pass to the useQuery hook
 * @param {?string} calledBy the product invoking the hook. Used for logging purposes
 * @returns {object} the query object result
 */
export default function usePersonSearch({ passThroughFilters = {} } = {}, options = {}, calledBy) {
  const { siteId } = useActiveSite();
  const flags = useFlags();
  const {
    query: { pagination, useIntentData, showNewContacts },
  } = useParamFilters();
  let filters = useParamFilters().query.filters;

  filters = {
    ...filters,
    ...(useIntentData && flags.prospectorIntentData ? { $useIntentData: true } : {}),
    ...(showNewContacts
      ? {
          idpDateAdded: {
            $gte: DateTime.now().minus({ months: 4 }).toISODate(),
          },
        }
      : {}),
  };

  const queryKey = [
    'idp/person',
    {
      query: {
        siteId,
        ...normalizePagination(pagination),
        ...normalizePersonFilters(filters),
        $join: ['fxContact'],
        ...passThroughFilters,
      },
      ...(calledBy ? { headers: { 'x-fx-called-by': calledBy } } : {}),
    },
  ];

  const person = api.useQuery(queryKey, {
    ...options,
    staleTime: Infinity, // disable refetching and only query when the query key changes
    enabled: !isEmpty({ ...filters } || {}) || options.enabled === true,
  });
  return { ...person, queryKey };
}

function normalizePagination(pagination = {}) {
  const $page = pagination?.page ?? 1;
  const $limit = pagination?.limit ?? 10;
  let $sort = pagination?.sortBy || '_score_desc';
  if ($sort) {
    const sortKey = $sort.split('_desc')[0];
    $sort = {
      [sortKey]: $sort.endsWith('_desc') ? -1 : 1,
      ...(sortKey === '_score' ? { contactInfoCount: -1 } : {}),
    };
  }
  return {
    ...($sort ? { $sort } : {}),
    ...($page ? { $skip: ($page - 1) * $limit } : {}),
    ...($limit ? { $limit } : {}),
  };
}
