import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Disclosure } from '@headlessui/react';
import { api as apiCore } from '@webfx/core-web';
import { useActiveSite } from '@webfx/web-hooks';
import { Icon } from '@webfx/web-ui';
import { hash } from '@webfx/web-utils';
import { isPlainObject } from 'lodash';

import camelCase from 'lodash/camelCase';
import styles from './DynamicFacet.module.css';

/**
 * Renders a dynamic facet component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.api - The API url to hit for data.
 * @param {string} [props.apiField='$field[]'] - The name of the field to use in the API request.
 * @param {string} props.label - The label to display for the facet.
 * @param {string} props.field - The name used for input field / and client query param.
 * @param {string} [props.facet] - The name of the facet property required by the API.
 * @param {string} [props.defaultValue] - The default Value to compare to if can be reset
 * @param {function} [props.transformOption] - transform the option object before rendering.
 * @param {Object} [props.query={}] - The query object to use in the API request.
 * @param {number} [props.refreshInterval=60] - The interval in seconds to refresh the facet data.
 * @param {boolean} [props.defaultOpen] - Whether the facet should be open by default.
 * @param {ReactNode} [props.icon] - The icon to display for the facet.
 * @param {string} [props.className] - Additional CSS classes to apply to the component.
 * @param {function} [props.onChange] - The function to call when the facet value changes.
 * @returns {ReactNode} The rendered dynamic facet component.
 */
export const DynamicFacet = ({
  api,
  apiField = '$field[]',
  label,
  field, // used as name for input and query
  facet, // use if the actual facet field is different that url field
  defaultValue,
  transformOption,
  query = {},
  refreshInterval = 60,
  defaultOpen,
  icon,
  className,
  onChange,
}) => {
  const site = useActiveSite();

  const { [facet || field]: fieldValue, $sort, ...restOfQuery } = query;
  const { data, isFetching } = apiCore.useQuery(
    [
      api,
      {
        [apiField]: facet || field,
        siteId: site.siteId,
        ...restOfQuery,
      },
    ],
    {
      refetchInterval: refreshInterval * 1000,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
    }
  );

  const options = facetsToOptions(data?.[0]?.values || [], transformOption);

  const activeFilterValue = urlQueryToFacetValue(fieldValue, options);
  const allKey = activeFilterValue?.length === 1 ? activeFilterValue[0] : null;
  const [hoverState, setHoverState] = useState(null);

  const canReset = hash(fieldValue) !== hash(defaultValue);

  const onOptionClick = (option) => () => {
    if (option === allKey) {
      onChange?.(null);
      return;
    }

    return onChange?.([option]);
  };

  const onInputChange = (event, options) => {
    const { value: val } = event.target;
    const allValues =
      activeFilterValue.length === 0 ? options.map((o) => o.value) : activeFilterValue;
    if (val === allKey) {
      onChange(null);
      return;
    }

    if (allValues.includes(val)) {
      const newValue = allValues.filter((v) => v !== val);
      onChange?.(newValue.length === 0 ? null : newValue);
    } else {
      return onChange?.([...activeFilterValue, val]);
    }
  };

  if (!options.length) {
    return null;
  }

  return (
    <Disclosure
      as="div"
      defaultOpen={defaultOpen}
      className={classnames(styles.container, className)}
      data-fx-name={`${camelCase(label)}`}
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            className={classnames(['d-flex align-items-center', styles.toggle])}
            data-fx-name="toggleButton"
          >
            <>
              {icon && typeof icon === 'string' ? (
                <Icon className={classnames([styles.icon, 'mr-2'])}>{icon}</Icon>
              ) : null}
              {icon && typeof icon !== 'string' ? icon : null}
            </>{' '}
            <span className={classnames([styles.label])} data-fx-name="label">
              {label}
            </span>
            {isFetching ? (
              <Icon className="spinner text-gray-500 ml-2" data-fx-name="loadingSpinner">
                refresh
              </Icon>
            ) : null}
            <div className="flex-fill"></div>
            <Icon
              className={classnames({
                'transform rotate-90': open,
                [styles.indicator]: true,
              })}
            >
              chevron_right
            </Icon>
          </Disclosure.Button>
          {canReset ? (
            <OverlayTrigger overlay={<Tooltip>Reset</Tooltip>}>
              {({ ref, ...triggerHandler }) => (
                <button
                  ref={ref}
                  type="button"
                  className={classnames([
                    'd-flex align-items-center justify-content-center',
                    styles.reset,
                  ])}
                  onClick={() => onChange?.(null)}
                  {...triggerHandler}
                >
                  <Icon className="font-18" outlined>
                    filter_alt_off
                  </Icon>
                </button>
              )}
            </OverlayTrigger>
          ) : null}
          <Disclosure.Panel
            as="ul"
            className={classnames({
              [styles.open]: open,
              [styles.options]: true,
            })}
            data-fx-name={`${camelCase(label)}Window`}
          >
            {options.map((opt) => (
              <li
                key={opt.value}
                className={classnames([styles.option, 'd-flex align-items-center'])}
              >
                <input
                  type="checkbox"
                  id={opt.value}
                  name={field || facet}
                  value={opt.value}
                  className={classnames([styles.optionCheckbox, 'mr-2'])}
                  checked={
                    activeFilterValue.includes?.(opt.value) || activeFilterValue?.length === 0
                  }
                  onChange={(event) => onInputChange(event, options)}
                  onMouseEnter={() => setHoverState(opt.value)}
                  onMouseLeave={() => setHoverState(null)}
                  data-fx-name="checkbox"
                />
                <button
                  type="button"
                  className={classnames({
                    'flex-fill': true,
                    [styles.optionLabel]: true,
                    [styles.togglesAll]: allKey === opt.value,
                    [styles.showToggle]: hoverState === opt.value,
                  })}
                  onClick={onOptionClick(opt.value)}
                  data-fx-name={`${camelCase(opt.label)}OptionLabel`}
                >
                  {opt.label}
                  <small className="text-gray-500 ml-1" data-fx-name="optionCount">
                    ({opt.count})
                  </small>
                </button>
              </li>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

DynamicFacet.propTypes = {
  api: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  refreshInterval: PropTypes.number,
};

function facetsToOptions(facets, transformOption) {
  return facets
    .map((facet) => {
      const option = {
        label: facet.facet,
        value: facet.facet,
        count: facet.count,
      };

      return transformOption ? transformOption(option, facets) : option;
    })
    .filter((o) => o?.value);
}

function urlQueryToFacetValue(value, facets) {
  // console.log({ value, facets });

  if (!value || !facets) {
    return [];
  }

  if (isPlainObject(value)) {
    if (value.$in) {
      return value.$in;
    }

    if (value.$ne) {
      return (facets || []).map((f) => f.value).filter((f) => !value.$ne.includes(f));
    }
  }

  if (Array.isArray(value)) {
    return value;
  }

  return [value];
}
