import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Spinner } from '@webfx/core-web';
import styles from './LoadMoreTrigger.module.css';
/**
 *
 * @param {object} props
 * @param {Function} props.onLoadMore - Function to call when the trigger is in view
 * @param {boolean} props.loading - Whether the trigger is loading
 * @param {boolean} props.disabled - Whether the trigger is disabled
 * @param {React.ReactNode} props.loader - Loader to display when the trigger is loading
 * @param {string} props.allLoadedMessage - Message to display when all items are loaded
 * @param {number} props.threshold - Threshold for the trigger to be in view
 * @param {string} props.className - Class name for the trigger
 * @returns
 */
export default function LoadMoreTrigger({
  onLoadMore,
  loading = false,
  disabled = false,
  loader = <Spinner inline size="sm" />,
  allLoadedMessage = null,
  threshold = 0.5,
  className,
}) {
  const { ref, inView } = useInView({
    threshold,
    initialInView: false,
    skip: disabled,
  });

  useEffect(() => {
    if (inView && !disabled && !loading) {
      onLoadMore();
    }
  }, [inView, disabled, onLoadMore, loading]);

  return (
    <div ref={ref} className={`${styles.trigger} ${className}`}>
      {loading ? loader : allLoadedMessage}
    </div>
  );
}
