import useRouter from './useRouter';

/**
 * Hook to determine if the current route should use the full page layout.
 * @returns {boolean}
 */
export default function useFullPageLayout() {
  const { active, params } = useRouter();

  if (active === 'mcfxReportAppendix' && params.type) {
    return true;
  }
  return false;
}
