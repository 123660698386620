import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ResizeProvider } from '@webfx/web-context';
import { Icon } from '@webfx/core-web';
import { Dropdown } from 'react-bootstrap';
import Menu from './Menu/Menu';

import Toggle from './Toggle';
import styles from './ViewAsSelector.module.css';

import './ViewAsSelector.css';

export default function ViewAsSelector({ variant }) {
  return (
    <div>
      <Dropdown className={styles.viewAsSelector}>
        <Dropdown.Toggle as={Toggle} variant={variant} data-fx-name="viewAs">
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip id="selector-tooltip">
                <span>View as...</span>
              </Tooltip>
            }
          >
            <Icon className={styles.icon} data-fx-name="currentClientProfile">
              account_circle
            </Icon>
          </OverlayTrigger>
        </Dropdown.Toggle>
        <ResizeProvider>
          <div className={styles.dropdownMenu}>
            <Dropdown.Menu className={styles.dropDownMenuOffset}>
              <Menu />
            </Dropdown.Menu>
          </div>
        </ResizeProvider>
      </Dropdown>
    </div>
  );
}
