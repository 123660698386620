import { actions, useTrackedStore } from '@webfx/web-state';

/**
 * Returns the layout state information
 * @returns {object}
 */
export default function useLayout() {
  const store = useTrackedStore();
  const banner = store.layout.banner();
  const modal = store.layout.modal();

  return {
    // state
    ...banner,
    showModal: modal.showModal,

    // actions
    setBanner: actions.layout.setBanner,
    setShowModal: actions.layout.setShowModal,
  };
}
